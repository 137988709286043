import React from 'react';
import Router from 'next/router';

import { useAuth } from '@/features/auth';
import { FullPageLoader } from '@/components/full-page-loader';

const Index = () => {
  const { isLoggedIn } = useAuth();

  React.useEffect(() => {
    let TIME_OUT_ID = null;

    if (isLoggedIn) {
      TIME_OUT_ID = setTimeout(() => Router.push('/dashboard'), 2500);
      return;
    }

    return () => clearTimeout(TIME_OUT_ID);
  }, [isLoggedIn]);

  return <FullPageLoader />;
};
export default Index;
